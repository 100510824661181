import axios from "axios";
import $g from "../common/index";
import { tgInitData } from "@/config/common/total";
let $config = import.meta.env;
let requestUrl = $config.VITE_BASE_URL;
let requestLength = 0;
let instance = axios.create({
  baseURL: requestUrl,
  timeout: 30000,
  header: {
    "content-type": "application/x-www-form-urlencoded", // 默认值
  },
});

const whiteUrlList = ["login"];
const needTgtokenUrlList = ["login", "register"];
// 请求拦截
instance.interceptors.request.use(
  function (config) {
    let token = $g.getStorage("token");
    let flag = false;
    try {
      flag = whiteUrlList.some((item) => config.url.endsWith(item));
    } catch (error) {
      //
    }
    if (token && !flag) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    let needTgtokenFlag = needTgtokenUrlList.some((item) =>
      config.url.endsWith(item)
    );
    if (needTgtokenFlag) {
      if (import.meta.env.VITE_MODEL === "development") {
        config.headers["Tg-Token"] =
          "user=%7B%22id%22%3A6654204999%2C%22first_name%22%3A%22Berna%22%2C%22last_name%22%3A%22Wolfe%22%2C%22username%22%3A%22bigfly_13%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-1759721394026430129&chat_type=private&auth_date=1729576818&hash=dde7a9068aa955ac6b4ed8e645fe47281fc9d5eea82b2a2d79078204caa3400f";
      } else {
        config.headers["Tg-Token"] = tgInitData.value;
      }
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
// 响应拦截
instance.interceptors.response.use(
  async function (res) {
    return Promise.resolve({ data: res.data, config: res.config });
  },
  function (err) {
    console.log("err=====", err);
    return Promise.reject(err);
  }
);
let api = {};

api.post = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then(async (res) => {
        let ret = res;
        if (res.data.code == -1) {
          requestLength++;
          $g.clearStorage();
          if (requestLength > 3) {
            return reject("Login fail");
          }
          ret = await reLogin(res.config);
        }
        return resolve(ret.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

api.get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

api.delete = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, { data: params })
      .then(async (res) => {
        let ret = res;
        if (res.data.code == -1) {
          requestLength++;
          $g.clearStorage();
          if (requestLength > 3) {
            return reject("Login fail");
          }
          ret = await reLogin(res.config);
        }
        return resolve(ret.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

api.put = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then(async (res) => {
        let ret = res;
        if (res.data.code == -1) {
          requestLength++;
          $g.clearStorage();
          if (requestLength > 3) {
            return reject("Login fail");
          }
          ret = await reLogin(res.config);
        }
        return resolve(ret.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

function reLogin(resConfig) {
  return new Promise(async (resolve, reject) => {
    try {
      let loginParams = {};
      if ($config.MODE == "development") {
        loginParams["tg_data"] = $config.VITE_TG_DATA;
        loginParams["is_dev"] = 1;
      } else {
        loginParams["tg_data"] = window.Telegram.WebApp.initData;
      }
      let authResult = await api.post("/portal/index/auth", loginParams);
      let authResultData = authResult.data;
      if (authResultData.token) {
        $g.setStorage("token", authResultData["token"]);
      }
      for (const key in authResultData) {
        $g.setStorage(key, authResultData[key]);
      }
      let params = {};
      if (resConfig.data) {
        params = JSON.parse(resConfig.data);
      }
      let result = await api.post(resConfig.url, params);
      return resolve({ data: result });
    } catch (error) {
      return reject(error);
    }
  });
}

export default api;

<template>
  <div>
    <div class="tab-bar" style="z-index: 10">
      <div v-for="(tab, index) in tabs" :key="index" class="div-item" @click="changeTab(index, tab.routeName)"
        :class="{ active: activeTab === index }" style="position: relative">
        <img class="icon" :src="activeTab === index ? tab.img1 : tab.img" alt="" />
        <div v-if="tab.count" class="hint"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { isShowTaskPage, tabsDataRef } from '@/config/common/total'
export default {
  components: {},
  data() {
    return {
      imgs: this.$store.state.sourceMsg,
      activeTab: 2,
    }
  },
  computed: {
    currentComponent() {
      return this.components[this.activeTab]
    },
    tabs() {
      return tabsDataRef.value
    },
  },
  watch: {
    '$route.name'(newRouteName) {
      const matchedTabIndex = this.tabs.findIndex(
        (tab) => tab.routeName === newRouteName
      )
      this.activeTab = matchedTabIndex
    },
  },
  created() {
    tabsDataRef.value = [
      {
        title: 'My Bottle',
        routeName: 'bottles',
        img: this.$store.state.sourceMsg.icon_bottle,
        img1: this.$store.state.sourceMsg.icon_bottle_full,
        count: 0,
      },
      {
        title: 'My Friend',
        routeName: 'friend',
        img: this.$store.state.sourceMsg.icon_friend,
        img1: this.$store.state.sourceMsg.icon_friend_full,
        count: 0,
      },
      {
        title: 'home',
        routeName: 'home',
        img: this.$store.state.sourceMsg.icon_home,
        img1: this.$store.state.sourceMsg.icon_home_full,
        count: 0,
      },
      {
        title: 'Mission',
        routeName: 'mission',
        img: this.$store.state.sourceMsg.icon_Mission,
        img1: this.$store.state.sourceMsg.icon_Mission_full,
        count: 0,
      },
      {
        title: 'My profile',
        routeName: 'profile',
        img: this.$store.state.sourceMsg.icon_profile,
        img1: this.$store.state.sourceMsg.icon_profile_full,
        count: 0,
      },
    ]
    if (!isShowTaskPage.value) {
      tabsDataRef.value = tabsDataRef.value.filter((item) => {
        return item.routeName !== 'mission'
      })
    }
  },
  methods: {
    changeTab(index, routeName) {
      console.log(routeName, index, 'tabs-change')
      this.$router.push(`/${routeName}`).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      this.activeTab = index
    },
  },
}
</script>

<style scoped lang="scss">
.icon {
  width: 1rem;
  height: 1rem;
}

.img {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.25rem;
}

.img1 {
  position: absolute;
  width: 1.5rem;
  height: 1rem;
  top: 0.25rem;
  left: 0.25rem;
}

.otherImg {
  font-size: 1.25rem;
}

.tab-bar {
  height: 4rem;
  font-size: 0.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.tab-bar>div {
  flex: 1;
  cursor: pointer;
  text-align: center;
  padding: 0.25rem;
}

.div-item {
  padding: 0.25rem;
  border-radius: 15px;
  color: rgb(146, 146, 146);

  .hint {
    position: absolute;
    top: -0.1rem;
    right: 1rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #ff0000;
  }
}

.active {
  /* background-color: rgb(255, 255, 255);
    border-radius:15px;
    border: 1px solid #494949; */
  color: #000000;
}

.tab-content {
  margin-top: 0.5rem;
}
</style>

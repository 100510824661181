<template>
  <div
    class="flex direction center daily_page model"
    :style="{ background: showTable ? '#D3F0FB' : '#fff' }"
  >
    <div class="Off" @click="close()"><img :src="imgs.off" alt="" /></div>
    <div class="boxTop" :style="{ 'background-image': `url(${imgs.sign1})` }">
      <div class="boxText">
        Daily Hunt<br />
        {{ signInDay }} days in a row
      </div>
      <div
        class="boxItem"
        :style="{ 'background-image': `url(${imgs.sign2})` }"
      >
        {{ signInDay }}
      </div>
    </div>
    <div class="dailyList" v-if="showTable">
      <div
        style="position: relative"
        v-for="(item, index) in dailyList"
        :key="index"
      >
        <div
          :class="[
            'item',
            signInDay === item.id ? 'itemDay' : '',
            item.type === 1 ? 'itemTwo' : item.type === 2 ? 'itemThree' : '',
          ]"
        >
          <div class="itemIndex">{{ item.id }}</div>
          <div class="itemImg">
            <div v-for="(imgi, index) in item.img" :key="index">
              <img :src="imgi" alt="" />
            </div>
          </div>
          <div class="itemNum">x1</div>
        </div>
        <div
          :class="[
            item.type === 1
              ? 'overTwo'
              : item.type === 2
              ? 'overThree'
              : 'over',
          ]"
          v-show="item.id < signInDay"
        >
          <img :src="imgs.finish" alt="" />
        </div>
      </div>
    </div>
    <div class="dailyList" v-else>
      <div class="giftImg">
        <img class="imgLight" :src="imgs.light" alt="" />
        <img class="imgToday" :src="todayItem.img[0]" alt="" />
      </div>
    </div>
    <div class="boxBottom" @click="claim()">
      {{ showTable ? 'Claim reward' : 'Awesome' }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      signInDay: 1,
      showTable: true,
      todayItem: {},
      imgs: this.$store.state.sourceMsg,
      dailyList: [
        // type：0-nomal，1-double，2-three
        {
          id: 1,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.fish1],
        },
        {
          id: 2,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.hermit],
        },
        {
          id: 3,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.octopus],
        },
        {
          id: 4,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.iring],
        },
        { id: 5, name: '11', type: 0, img: [this.$store.state.sourceMsg.rum] },
        {
          id: 6,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.hermit],
        },
        {
          id: 7,
          name: '11',
          type: 1,
          img: [
            this.$store.state.sourceMsg.coin,
            this.$store.state.sourceMsg.bottle,
          ],
        },
        {
          id: 8,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.octopus],
        },
        {
          id: 9,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.iring],
        },
        {
          id: 10,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.hermit],
        },
        {
          id: 11,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.conch],
        },
        {
          id: 12,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.coin],
        },
        {
          id: 13,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.fish1],
        },
        {
          id: 14,
          name: '11',
          type: 1,
          img: [
            this.$store.state.sourceMsg.coin,
            this.$store.state.sourceMsg.bottle,
          ],
        },
        {
          id: 15,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.conch],
        },
        {
          id: 16,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.hermit],
        },
        {
          id: 17,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.octopus],
        },
        {
          id: 18,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.iring],
        },
        { id: 19, name: '11', type: 0, img: [this.$store.state.sourceMsg.rum] },
        {
          id: 20,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.coin],
        },
        {
          id: 21,
          name: '11',
          type: 1,
          img: [
            this.$store.state.sourceMsg.box1,
            this.$store.state.sourceMsg.bottle,
          ],
        },
        {
          id: 22,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.octopus],
        },
        {
          id: 23,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.iring],
        },
        { id: 24, name: '11', type: 0, img: [this.$store.state.sourceMsg.rum] },
        {
          id: 25,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.conch],
        },
        {
          id: 26,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.hermit],
        },
        {
          id: 27,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.coin],
        },
        {
          id: 28,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.fish1],
        },
        {
          id: 29,
          name: '11',
          type: 0,
          img: [this.$store.state.sourceMsg.conch],
        },
        {
          id: 30,
          name: '11',
          type: 2,
          img: [
            this.$store.state.sourceMsg.coin,
            this.$store.state.sourceMsg.bottle,
            this.$store.state.sourceMsg.box1,
          ],
        },
      ],
    }
  },
  created() {
    // todo 获取签到天数
    this.signInDay = 8
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    close() {
      this.$emit('close')
    },
    claim() {
      if (this.showTable) {
        this.showTable = false
        let list = this.dailyList
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === this.signInDay) {
            console.log(list[i])
            this.todayItem = list[i]
          }
        }
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.model {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.7rem;
  .Off {
    z-index: 2005;
    position: absolute;
    right: 0rem;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .boxTop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    .boxText {
      text-align: start;
      position: absolute;
      left: 1.5rem;
      top: 0.9rem;
      font-size: 0.5rem;
      color: #fff;
    }
    .boxItem {
      position: absolute;
      right: 1.2rem;
      top: 1rem;
      width: 2.2rem;
      height: 2.2rem;
      background-size: contain;
      background-repeat: no-repeat;
      line-height: 2.7rem;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .dailyList {
    width: 95%;
    margin: 0 auto;
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      position: relative;
      background-color: #fff;
      color: #8698b7;
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 0.2rem;
      font-size: 0.5rem;
      margin: 0.3rem;
      .itemIndex {
        position: absolute;
        top: 0.05rem;
        left: 0.1rem;
      }
      .itemImg {
        position: absolute;
        display: flex;
        top: 0.5rem;
        left: 0.5rem;
        width: 0.9rem;
        height: 0.9rem;
        img {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
      .itemNum {
        position: absolute;
        bottom: 0.05rem;
        right: 0.1rem;
      }
    }
    .itemTwo {
      width: 4.4rem;
      height: 1.9rem;
      background-color: #fef0ac;
      .itemImg {
        position: absolute;
        display: flex;
        justify-content: space-around;
        top: 0.5rem;
        left: 0.7rem;
        width: 3rem;
        height: 0.9rem;
        img {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
    .itemThree {
      width: 6.9rem;
      height: 1.9rem;
      background-color: #fef0ac;
      .itemImg {
        position: absolute;
        display: flex;
        justify-content: space-around;
        top: 0.5rem;
        left: 0.5rem;
        width: 5.5rem;
        height: 0.9rem;
        img {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
    .itemDay {
      border: 0.05rem solid #39cce9;
    }
    .over {
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      background: rgb(0, 0, 0, 0.5);
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1rem;
        height: 0.8rem;
      }
    }
    .overTwo {
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      background: rgb(0, 0, 0, 0.5);
      width: 4.4rem;
      height: 1.9rem;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1rem;
        height: 0.8rem;
      }
    }
    .overThree {
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      background: rgb(0, 0, 0, 0.5);
      width: 6.9rem;
      height: 1.9rem;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1rem;
        height: 0.8rem;
      }
    }
    .giftImg {
      position: relative;
      width: 90%;
      margin: 0 auto;
      .imgLight {
        width: 9rem;
      }
      .imgToday {
        position: absolute;
        left: 4.4rem;
        top: 2rem;
        width: 5rem;
        height: 5rem;
      }
    }
  }
  .boxBottom {
    width: 90%;
    height: 2.35rem;
    background: #39cce9;
    color: white;
    font-size: 0.85rem;
    line-height: 2.35rem;
    border-radius: 0.5rem;
    margin: 1rem auto;
  }
}
</style>

<template>
  <div class="direction center spin_page bgSea" :style="{ 'background-image': `url(${imgs.sendBg})` }">
    <div class="top">
      <div style="font-size: 1.1rem; font-weight: bold">Lucky Wheel</div>
      <div style="font-size: 0.7rem">
        Log in every day and claim your rewards!
      </div>
      <div style="font-size: 1.1rem; font-weight: bold">Resets in 16h 31m</div>
    </div>
    <div style="position: relative">
      <div class="wheel">
        <img class="wheelBg" :src="imgs.turntable" alt="" />
        <img class="wheelBtn" :src="imgs.start" alt="" />
        <LuckyWheel class="stroke1" style="direction: ltr; z-index: 99; position: relative" ref="myLucky" width="13rem"
          height="13rem" :prizes="prizes" :default-config="whellConfig" @start="startCallback" @end="endCallback" />
      </div>
    </div>
    <div class="btn" @click="startCallback(0)" v-if="!isFree">
      <div>Spin</div>
      <div class="price"><img :src="imgs.coin" alt="" />10</div>
      <div class="tree"><img :src="imgs.decoration_btn" alt="" /></div>
    </div>
    <div class="btn" @click="startCallback(1)" v-else>
      <div>Free</div>
      <div class="tree"><img :src="imgs.decoration_btn" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRing: false, // 是否旋转状态
      isFree: false,
      // 奖励
      giftNum: 0,
      index: 0,
      showRing: false,
      imgs: this.$store.state.sourceMsg,
      prizes: [
        {
          id: 1,
          background: '#B1ECFE',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.pearloyster,
              width: '2rem',
              top: '0.9rem',
            },
          ],
        },
        {
          id: 2,
          background: '#99D1FD',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.conch,
              width: '2rem',
              top: '1rem',
            },
          ],
        },
        {
          id: 3,
          background: '#E8BEFE',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.fish2,
              width: '2rem',
              top: '1rem',
            },
          ],
        },
        {
          id: 4,
          background: '#FCCAFD',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.rum,
              width: '2rem',
              top: '1rem',
            },
          ],
        },
        {
          id: 5,
          background: '#FAC9D6',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.octopus,
              width: '2rem',
              top: '1rem',
            },
          ],
        },
        {
          id: 6,
          background: '#FDE1DE',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.fish1,
              width: '2rem',
              top: '1rem',
            },
          ],
        },
        {
          id: 7,
          background: '#F8EDCD',
          fonts: [
            {
              fontColor: '#000',
              fontWeight: '600',
              fontSize: '0.75rem',
              text: 'X1',
              top: '3rem',
            },
          ],
          imgs: [
            {
              src: this.$store.state.sourceMsg.sendBottle_full,
              width: '1.5rem',
              top: '1rem',
            },
          ],
        },
      ],
      whellConfig: {
        gutter: 0,
        stopRange: 0.8, // 停止范围
        speed: 20, // 速度
        accelerationTime: 1500, // 开始转的时间
        decelerationTime: 3000, // 结束转的时间
      },
    }
  },
  created() {
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/home' })
    })
    this.getItemList()
  },
  mounted() { },
  beforeUnmount() { },
  methods: {
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    startCallback() {
      if (!this.isRing) {
        this.isRing = true
        // 调用抽奖组件的play方法开始游戏
        this.$refs.myLucky.play()
        let index = 0
        setTimeout(() => {
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(index)
        }, 500)
      } else {
        console.log('waiting...')
      }
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log('11111111', prize)
      this.isRing = false
    },
    getItemList() {
      // 调用注册接口
      this.$api
        .getItem()
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            const data = res.data
          } else {
            console.log('register error')
          }
        })
        .catch((err) => {
          console.log('err=========================', err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.wheel {
  margin: 0 auto;
  width: 13rem;
  height: 13rem;
  position: relative;
  padding-top: 2rem;
  margin-bottom: 1rem;

  .wheelBg {
    width: 14.5rem;
    position: absolute;
    z-index: 10;
    top: 1.2rem;
    left: -0.7rem;
  }

  .wheelBtn {
    width: 2.75rem;
    position: absolute;
    z-index: 100;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bgSea {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.top {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-shadow: rgb(53 144 195) 0rem 0.15rem;
}

.start {
  z-index: 1;
  position: absolute;
  width: 3.5rem;
  height: 4.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
  }
}

.btn {
  width: 8.4rem;
  height: 2.35rem;
  margin: 2rem auto;
  background: #39cce9;
  border-radius: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    img {
      width: 1rem;
      height: 1rem;
      margin: 0 0.2rem;
    }
  }

  .tree {
    position: absolute;
    right: -1rem;
    width: 2.4rem;
    height: 2.6rem;

    img {
      width: 2.35rem;
      height: 2.6rem;
    }
  }
}
</style>

<template>
  <div class="direction center send_page bgSea" @click="handleOutside" :style="{ height: boxHeight / 3 + 'px' }">
    <div class="box" :style="{ height: boxHeight + 'px' }">
      <div class="top">
        <div class="checkBottle">
          <div class="down" @click="this.show = !this.show">
            <div class="bottleType">
              {{ bottleType }}
            </div>
            <div class="bottomIcon">
              <img :src="bottleIcon[bottleType]" alt="" />
              <svg :class="{ rotated: show, 'rotated-back': !show }" t="1716949010473" class="icon"
                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6850" width="1rem"
                height="1rem">
                <path
                  d="M512.146286 619.52L245.296762 352.792381 193.584762 404.48l318.585905 318.415238 318.268952-318.415238-51.736381-51.687619z"
                  p-id="6851" fill="#ffffff"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="edit">
          <div class="editItem" @click="handlePaper(1)">
            <div class="colorChoose"></div>
          </div>
          <div class="editItem" style="background-color: #daf6fa" @click="handlePaper(2)">
            <img :src="imgs.fontFamily" alt="" />
          </div>
          <div class="editItem" style="background-color: #daf6fa" @click="upload">
            <input ref="upload" accept="image/*" type="file" style="display: none" @change="change" />
            <img :src="imgs.photo" alt="" />
          </div>
          <div class="editItem" @click="this.$router.push({ path: '/home' })">
            <img :src="imgs.off" alt="" />
          </div>
        </div>
      </div>
      <div class="editBack" :style="{ 'background-image': `url(${paper})` }">
        <div class="textImg" v-show="showImg">
          <img class="deleteImg" @click="handleDeleteImg" :src="imgs.off" alt="" />
          <img :src="url" alt="" @click="handlePreview(url)" />
        </div>
        <van-field class="textField" v-model="message" style="overflow: scroll; margin-top: 0.6rem" rows="2" autosize
          type="textarea" placeholder="Write something..." maxlength="800" />
      </div>
      <div class="bottomText">
        <div class="sendFriend" v-show="bottleType === 'Common'">
          Send to:All
        </div>
        <div class="sendFriend" v-show="bottleType === 'Friend'" @click="showFriend = true">
          <div>
            Send to:{{
              friendForm.gender == 1
                ? 'Men'
                : friendForm.gender == 2
                  ? 'Women'
                  : 'All'
            }}·{{ ageArr[0] }}-{{ ageArr[1] }}
          </div>
          <svg style="margin-right: 1rem" t="1716982417652" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="2480" width="0.8rem" height="0.8rem">
            <path
              d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415zM947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
              fill="#8698B7" p-id="2481"></path>
          </svg>
        </div>
        <div class="sendFriend" v-show="bottleType === 'Directed'" @click="showDirected = true">
          <div>
            Send to:&ensp;
            <flag class="flag" :country="this.directedForm.countryCode" size="normal" />
            &ensp;·&ensp;{{ ageArr[0] }}-{{ ageArr[1] }}
          </div>
          <svg style="margin-right: 1rem" t="1716982417652" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="2480" width="0.8rem" height="0.8rem">
            <path
              d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415zM947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
              fill="#8698B7" p-id="2481"></path>
          </svg>
        </div>
        <div class="sendTo" @click="handleSendTo">
          <img src="/imgs/bottleType/font_2.png" alt="" />
        </div>
      </div>
    </div>
    <!-- change bottle -->
    <van-action-sheet class="changeModel" v-model:show="show" @select="onSelect">
      <div class="topTip"></div>
      <div class="changeTitle">Choose bottle</div>
      <div class="bottleItem" v-for="(item, index) in bottleList" :key="index" :class="{ active: activeTab === index }"
        :style="{
          backgroundColor:
            item.count === 0 && item.freeCount === 0 && item.type !== 3
              ? '#E6E6E6'
              : '',
        }" @click="chooseBottle(index, item)">
        <div class="bottleIcon">
          <img v-show="item.count != 0 || item.freeCount != 0" :src="bottleIcon[item.name]" alt="" />
          <img v-show="item.count === 0 && item.freeCount === 0" :src="bottleIcon[item.name + '_null']" alt="" />
          <div :style="{
            color: item.count === 0 && item.freeCount === 0 ? '#B7B7B7' : '',
          }">
            <span class="remain-count" v-if="item.freeCount"
              style="font-size: 0.75rem; padding-left: 0.1rem">free</span>
            <span v-else style="font-size: 0.75rem; padding-left: 0.1rem">
              x{{ item.count > 99 ? '99+' : item.count }}</span>
          </div>
        </div>
        <div class="bottleInfo">
          <div class="bottleName">
            <div :style="{
              color:
                item.count === 0 && item.freeCount === 0 && item.type !== 3
                  ? '#B7B7B7'
                  : '',
            }">
              {{ item.name }}
            </div>
            <div class="coin" v-show="item.name === 'Directed' &&
              item.count === 0 &&
              item.freeCount === 0
              ">
              <img :src="imgs.coin" alt="" /><span :style="{ color: remainGold < 10 ? 'red' : '' }">10</span>
            </div>
          </div>
          <div class="bottleDesc" :style="{
            color:
              item.count === 0 && item.freeCount === 0 && item.type !== 3
                ? '#BBBBBB'
                : '',
          }">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <div class="confirm" @click="confirm(0)">Confirm</div>
    </van-action-sheet>
    <!-- change friend -->
    <van-action-sheet class="changeModel" v-model:show="showFriend" @select="onSelect">
      <div class="topTip"></div>
      <div class="changeTitle">Send bottle to</div>
      <div class="gender">
        <div class="genderTitle">gender</div>
        <div class="genderList">
          <div class="genderItem" v-for="(item, index) in genderList" :key="index" @click="changeGender(item, index)"
            :class="{ active: activeGender === index }">
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="age">
        <div class="ageTitle">age</div>
        <div class="ageItem">
          <div class="num">{{ ageArr[0] }}</div>
          <van-slider style="height: 0.4rem; width: 70%" :min="1" :max="99" class="slide" v-model="ageArr" range />
          <div class="num">{{ ageArr[1] }}</div>
        </div>
      </div>
      <div class="confirm" @click="confirm(1)">Confirm</div>
    </van-action-sheet>
    <!-- change directed country  -->
    <van-action-sheet class="changeModel" v-model:show="showDirected" @select="onSelect">
      <div class="topTip"></div>
      <div class="changeTitle">Send bottle to</div>
      <div class="gender">
        <div class="genderTitle">Nation</div>
        <div class="genderList">
          <div class="flagAndCountry" @click="changeCountry(item, index)">
            <flag class="flag" :country="this.directedForm.countryCode" size="normal" />
            <div>{{ directedForm.country }}</div>
          </div>
        </div>
      </div>
      <div class="age">
        <div class="ageTitle">age</div>
        <div class="ageItem">
          <div class="num">{{ ageArr[0] }}</div>
          <van-slider style="height: 0.4rem; width: 70%" :min="1" :max="99" class="slide" v-model="ageArr" range />
          <div class="num">{{ ageArr[1] }}</div>
        </div>
      </div>
      <div class="confirm" @click="confirm(2)">Confirm</div>
    </van-action-sheet>
    <!-- change font -->
    <van-action-sheet class="changeModel" v-model:show="showFontFamily">
      <div class="topTip"></div>
      <div class="changeTitle">Feathers</div>
      <div class="gender">
        <van-row :gutter="[20, 20]">
          <template v-for="(item, index) in fontFamilyList" :key="index">
            <van-col :span="index < 3 ? 8 : 12">
              <div class="fontFamilyItem" @click="changeFontFamily(item, index)"
                :class="{ active: activeFontFamily === index }">
                <img class="fontFamilyLogo" :src="item.img" alt="" />
                <div class="fontFamilyName">{{ item.name }}</div>
                <div v-if="item.count === 0" class="money" style="font-size: 0.7rem">
                  ∞
                </div>
                <div v-else class="money">
                  <img :src="imgs.coin" alt="" />
                  {{ item.count }}
                </div>
              </div>
            </van-col>
          </template>
        </van-row>
      </div>
      <div class="fontBottom">
        <div class="fontCoin"><img :src="imgs.coin" alt="" />10</div>
        <div class="fontBuy" @click="this.showFontFamily = false">Buy</div>
      </div>
    </van-action-sheet>
    <!-- change paper -->
    <van-action-sheet class="changeModel" v-model:show="showPaper">
      <div class="topTip"></div>
      <div class="changeTitle">Parchments</div>
      <div class="gender">
        <van-row :gutter="[20, 20]">
          <template v-for="(item, index) in paperList" :key="item.paperId">
            <van-col :span="index < 3 ? 8 : 12">
              <div class="fontFamilyItem" @click="changePaper(item, index)" :class="{ active: activePaper === index }">
                <img class="fontFamilyLogo" :src="item.img" alt="" />
                <div class="fontFamilyName">{{ item.name }}</div>
                <div v-if="item.count === 0" class="money" style="font-size: 0.7rem">
                  ∞
                </div>
                <div v-else class="money">
                  <img :src="imgs.coin" alt="" />
                  {{ item.count }}
                </div>
              </div>
            </van-col>
          </template>
        </van-row>
      </div>
      <div class="fontBottom">
        <div class="fontCoin">
          <img :src="imgs.coin" alt="" />{{ directedGold }}
        </div>
        <div class="fontBuy" @click="paperBuy">Buy</div>
      </div>
    </van-action-sheet>
    <selectCounty v-if="showSelectCounty" @selectCountry="selectCountry" @closeCountry="closeCountry">
    </selectCounty>
    <!-- confirm send -->
    <van-dialog v-model:show="showSend" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showSend = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="bottleIcon[bottleType]" alt="" />
        <div class="sendTitle">Send bottle</div>
        <div class="sendContext">
          {{ sendBottleDes }}<br />
          Please comfirm that the Information<br />
          you have tilled In Is correct
        </div>
        <div class="sendBtn" @click="handleConfirmSend">Send</div>
      </div>
    </van-dialog>
    <!-- store -->
    <van-dialog v-model:show="isShowNavigateShop" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="isShowNavigateShop = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">not enough gold</div>
        <div class="sendContext">
          You don't have enough gold. <br />
          Would you like to go to the store to purchase more?<br />
        </div>
        <div class="sendBtn" @click="handleConfirmNavagateShop">Go</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import selectCounty from '../../components/Select/country.vue'
import flag from 'vue-country-flag-next'
import {
  fontFamilyList,
  paperList,
  compressImage,
  getUploadUrl,
  generateUniqueString,
} from '@/config/common/dic'
import {
  todayRemainTImes,
  todayBottleNumInfo,
  userTotalInfo,
  getUserInitInfo,
  isShowFeatureConfig,
  bottleTypeRef,
  bottleConfig,
  bottlePageRef,
  bottleListRef,
} from '@/config/common/total'
import { showToast, showImagePreview } from 'vant'

export default {
  components: { selectCounty, flag },
  data() {
    return {
      imgs: this.$store.state.sourceMsg,
      paper: this.$store.state.sourceMsg.paper1,
      message: '',
      activeTab: 0,
      activeGender: 0,
      activeName: 0,
      activeFontFamily: 0,
      activePaper: 0,
      show: false,
      showFriend: false,
      showDirected: false,
      showSelectCounty: false,
      showFontFamily: false,
      showPaper: false,
      showSend: false,
      bottleType: '',
      bottleList: [
        {
          name: 'Common',
          desc: 'Send your message anonymousely in a bottle',
          type: 1,
          count: 0,
        },
        {
          name: 'Friend',
          desc: 'Send your message anonymousely in a bottle',
          type: 2,
          count: 0,
        },
        {
          name: 'Directed',
          desc: 'Send your message anonymousely in a bottle',
          type: 3,
          count: 0,
        },
      ],
      bottleIcon: {
        Common: '/imgs/bottleType/bottle.png',
        Friend: '/imgs/bottleType/friendBottle.png',
        Directed: '/imgs/bottleType/directedBottle.png',
        Common_null: '/imgs/bottleType/bottled.png',
        Friend_null: '/imgs/bottleType/friendBottle_null.png',
        Directed_null: '/imgs/bottleType/directedBottle_null.png',
      },
      genderList: [
        { label: 'All', id: 0 },
        { label: 'Men', id: 1 },
        { label: 'Women', id: 2 },
      ],
      ageArr: [16, 30],
      friendForm: {
        age: [24, 40],
        gender: 0,
      },
      directedForm: {
        country: 'France',
        countryCode: 'fr',
        age: [24, 40],
      },
      fontFamilyList: [],
      paperList: [],
      showImg: false,
      url: '',
      showToastFlag: false,
      isShowNavigateShop: false,
      remoteUrl: '',
      isShowFont: false,
      reqFlag: false,
      isIos: false,
      boxHeight: 0,
    }
  },
  computed: {
    remainGold() {
      return userTotalInfo.value?.gold || 0
    },
    directedGold() {
      return bottleConfig.value?.throw_bottle_cost_3?.value || 0
    },
    freeInfo() {
      return todayBottleNumInfo.value
    },
    isDirectedPay() {
      return (
        this.activeTab == 2 &&
        this.bottleList[2].count <= 0 &&
        this.bottleList[2].freeCount <= 0
      )
    },
    sendBottleDes() {
      if (this.isDirectedPay) {
        return 'You need to spend 10 coins to send the bottle, do you want to proceed?'
      }
      return 'Do you want to send the bottle?'
    },
    // sortedBottleList() {
    //   return this.bottleList.slice().sort((a, b) => {
    //     // 优先按 freeCount 降序排序，如果没有则按 count 排序
    //     if (a.freeCount && b.freeCount) {
    //       return b.freeCount - a.freeCount // 按 freeCount 降序
    //     } else if (a.freeCount) {
    //       return -1 // a 有 freeCount，优先排序
    //     } else if (b.freeCount) {
    //       return 1 // b 有 freeCount，优先排序
    //     } else {
    //       return b.count - a.count // 按 count 降序
    //     }
    //   })
    // },
  },
  created() {
    this.boxHeight = window.innerHeight
    // 判断bottle数量是否为0
    this.isBottle()
    this.fontFamilyList = fontFamilyList()
    this.paperList = paperList()
    this.isShowFont = isShowFeatureConfig.value.fontConfig
    // this.isIos = this.isAppleDevice()
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    console.log(todayRemainTImes.value, 9991)
    window.addEventListener('resize', () => {
      this.boxHeight = window.innerHeight
    })
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('resize', () => {
      this.boxHeight = window.innerHeight
    })
  },
  methods: {
    isAppleDevice() {
      console.log(navigator.userAgent, '34444')
      showToast(navigator.userAgent)
      return /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)
    },
    handleClickOutside(event) {
      try {
        const inputEl = this.$refs.input
        if (!inputEl?.contains(event.target)) {
          inputEl?.blur() // 关闭键盘
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleOutside(event) {
      event?.stopPropagation()
    },
    handlePaper(type = 1) {
      if (!this.isShowFont) {
        return showToast('Coming soon.')
      }
      if ((type = 1)) {
        this.showPaper = true
      } else {
        this.showFontFamily = true
      }
    },
    // 判断bottle数量是否为0
    isBottle() {
      Object.entries(todayBottleNumInfo.value).forEach(([key, value]) => {
        const bottle = this.bottleList.find((item) => item.type == key)
        if (bottle) {
          bottle.count = value
          const freeCount = todayRemainTImes.value[key] ?? 0
          bottle.freeCount = freeCount >= 0 ? freeCount : 0
        }
      })

      console.log(this.bottleList, 'bottleList')
      const foundItem = this.bottleList.find(
        (item) => item?.count != 0 || item?.freeCount != 0
      )
      if (foundItem) {
        this.bottleType = foundItem.name
        this.activeName = foundItem.name
        this.activeTab = this.bottleList.indexOf(foundItem)
      } else {
        this.bottleType = this.bottleList[2].name
        this.activeName = this.bottleList[2].name
        this.activeTab = 2
      }
      console.log(this.bottleType, this.activeName, '43961')
    },
    onSelect(item) {
      this.show = false
      this.bottleType = item.name
    },
    chooseBottle(index, item) {
      if (
        item.count ||
        item.freeCount ||
        (item.type === 3 && this.remainGold > 10)
      ) {
        this.activeName = item.name
        this.activeTab = index
        return
      }
      if (item.type === 3) {
        this.isShowNavigateShop = true
      }
    },
    handleConfirmNavagateShop() {
      this.isShowNavigateShop = false
      this.$router.push('/shopping')
    },
    confirm(index) {
      if (index === 0) {
        this.bottleType = this.activeName
        this.show = false
      } else if (index === 1) {
        this.showFriend = false
      } else {
        this.showDirected = false
      }
    },
    changeGender(item, index) {
      this.friendForm.gender = item.id
      this.activeGender = index
    },
    changeFontFamily(item, index) {
      this.activeFontFamily = index
    },
    changePaper(item, index) {
      this.activePaper = index
    },
    changeCountry() {
      this.showSelectCounty = true
      this.showDirected = false
    },
    selectCountry(item) {
      this.directedForm.country = item.name
      this.directedForm.countryCode = item.code
      this.showSelectCounty = false
      this.showDirected = true
    },
    closeCountry() {
      this.showSelectCounty = false
      this.showDirected = true
    },
    paperBuy() {
      this.showPaper = false
      this.paper = this.paperList[this.activePaper].img
    },
    upload() {
      this.$refs.upload.click()
    },
    async change(event) {
      const selectedFile = event.target.files[0] // 获取用户选择的文件
      this.url = URL.createObjectURL(selectedFile)
      this.showImg = true
      const remoteUrl = await getUploadUrl(selectedFile)
      this.remoteUrl = remoteUrl
    },
    handleSendTo() {
      let message = this.message.trim()
      if (message.length < 6) {
        return showToast(
          'The message is too short, please enter at least 6 characters'
        )
      }
      if (this.url && !this.remoteUrl) {
        return showToast('Please wait for the image upload to complete')
      }
      this.showSend = true
    },
    handleDeleteImg() {
      this.showImg = false
      this.url = ''
      this.remoteUrl = ''
    },
    handlePreview(url) {
      console.log(url, 'url->>>')
      url && showImagePreview([url])
    },
    // 真正调用接口
    handleConfirmSend() {
      try {
        if (this.reqFlag) return
        this.reqFlag = true
        const __token__ = generateUniqueString()
        const type =
          this.bottleList.findIndex((item) => item.name === this.bottleType) + 1

        const config = {
          url: this.remoteUrl,
          paper: this.activePaper,
          fontFamily: this.activeFontFamily,
        }
        const data = {
          gender: this.friendForm.gender,
          min_age: this.ageArr[0],
          max_age: this.ageArr[1],
          type,
          nation: this.directedForm.countryCode,
          content: this.message,
          config,
          __token__,
        }
        console.log(data, 'data')
        this.$api
          .throwBottle(data)
          .then((res) => {
            this.reqFlag = false
            if (res.code === 0) {
              bottleTypeRef.value = type
              getUserInitInfo()
              this.getBottleListFn()
              return this.$router.push('/sendTo')
            }
            return showToast(res?.message)
          })
          .catch((e) => {
            this.reqFlag = false
          })
      } catch (error) {
        console.log(error, 'error->>>')
      }
    },
    getBottleListFn() {
      bottlePageRef.value = 1
      this.$api.getBottleList(bottlePageRef.value, 10).then((res) => {
        if (res.code === 0 && res.data.data.length > 0) {
          bottleListRef.value = res.data.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  background: linear-gradient(to bottom, #acecf9, #d6fdfa);
  height: 31vh;
}

.box {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  width: 90%;
  margin: 1rem auto;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.down {
  width: 6.5rem;
  height: 2rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
  background-color: #010101;

  .bottleType {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  .bottomIcon {
    display: flex;
    align-items: center;

    img {
      width: 1rem;
    }

    .icon {
      color: white;
    }
  }
}

.downMeau {
  background-color: #fff;
  border-radius: 0.5rem;
  font-size: 0.8rem;

  .downMeauItem {
    display: flex;
    line-height: 1.5rem;
    padding-left: 0.6rem;
  }

  .addBorder {
    border-bottom: 1px solid #ededed;
  }
}

.edit {
  display: flex;

  .editItem {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .colorChoose {
    width: 70%;
    height: 70%;
    border-radius: 1rem;
    background-color: #d38f8f;
  }
}

.bottomText {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  height: 4rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .sendFriend {
    height: 3rem;
    width: 12rem;
    background-color: #f3f5f9;
    border-radius: 0.55rem;
    color: #8698b7;
    line-height: 3rem;
    text-align: start;
    padding-left: 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sendTo {
    height: 3rem;
    width: 4rem;
    background-color: #39cce9;
    border-radius: 0.55rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 2rem;
      width: 2rem;
    }
  }
}

.editBack {
  width: 17rem;
  height: calc(100% - 4rem - 4rem);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.25rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .textImg {
    position: relative;
    width: 200px;
    /* max-height: 270px; */
    margin-top: 2rem;

    .deleteImg {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 2rem;
      height: 2rem;
    }

    img {
      max-width: 4rem;
      max-height: 6.5rem;
      border-radius: 0.5rem;
      object-fit: contain;
    }
  }

  .textField {
    margin: 0 auto;
    height: 90%;
    background-color: rgba(255, 255, 255, 0);
  }
}

.changeModel {
  .topTip {
    top: 0.5rem;
    width: 4rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    background-color: #dddddd;
    border-radius: 0.5rem;
  }

  .changeTitle {
    text-align: start;
    margin: 1rem 1rem 0.5rem 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: #39cce9;
  }

  .bottleItem {
    padding: 0 0.8rem;
    display: flex;
    justify-content: space-between;
    width: 83%;
    margin: 0 auto;
    background-color: #f0f3f8;
    border-radius: 0.5rem;
    height: 4rem;
    margin-bottom: 0.9rem;
    border: 1px solid transparent;
  }

  .bottleIcon {
    position: relative;
    width: 18%;
    height: 4rem;
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
    }

    div {
      position: absolute;
      top: 0.5rem;
      left: 1.5rem;
      color: #39cce9;
    }
  }

  .bottleInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 79%;
    line-height: 1.2rem;

    div {
      text-align: start;
    }

    .bottleName {
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .coin {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;

        img {
          width: 0.8rem;
          margin-right: 0.25rem;
        }
      }
    }

    .bottleDesc {
      font-size: 0.56rem;
      word-wrap: normal;
      color: #8698b7;
    }
  }

  .confirm {
    width: 80%;
    margin: 0 auto;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #39cce9;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
}

.gender {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  .genderTitle {
    text-align: start;
    margin-bottom: 0.5rem;
  }

  .genderList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  .genderItem {
    width: 5rem;
    height: 1.8rem;
    background-color: #f0f3f8;
    color: #8698b7;
    line-height: 1.8rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .flagAndCountry {
    padding: 0 0.5rem;
    height: 1.8rem;
    background-color: #f0f3f8;
    color: #8698b7;
    line-height: 1.8rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;

    .flag {
      margin-right: 0px;
      border-radius: 0.25rem;
      box-shadow: 0rem 0rem 0.25rem rgb(0, 0, 0);
    }
  }
}

.flag {
  margin-right: 10px;
  border-radius: 0.25rem;
  box-shadow: 0rem 0rem 0.25rem rgb(0, 0, 0);
}

.age {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0.5rem auto;

  .ageTitle {
    text-align: start;
    margin-bottom: 0.5rem;
  }

  .ageItem {
    width: 95%;
    padding: 0 0.5rem;
    background-color: #f0f3f8;
    height: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slide {
      width: 60%;
    }

    .num {
      width: 10%;
    }
  }
}

.fontFamilyItem {
  background: #f0f3f8;
  border-radius: 0.5rem;
  height: 4.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  .fontFamilyName {
    font-size: 0.5rem;
    margin: 0.2rem 0;
  }

  .fontFamilyLogo {
    width: 0.75rem;
    height: 1.6rem;
  }

  .money {
    background: #39cce9;
    border-radius: 0.35rem;
    padding: 0.1rem 0;
    width: 2rem;
    height: 0.7rem;
    color: #fff;
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.2rem;
    }
  }
}

.fontBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto;

  .fontCoin {
    background: #daf6fa;
    height: 1.75rem;
    width: 3.15rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    color: #8698b7;

    img {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .fontBuy {
    width: 3.5rem;
    height: 2rem;
    background: #39cce9;
    border-radius: 0.5rem;
    font-size: 0.85rem;
    line-height: 2rem;
    color: #fff;
  }
}

.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;

    img {
      width: 100%;
    }
  }

  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }

  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }

  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }

  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}

.active {
  border: 1px solid #5ed4ec !important;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.rotated-back {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
</style>

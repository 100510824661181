<template>
  <div class="direction center sendTo_page finshiBg" :style="{ 'background-image': `url(${imgs.sendBg})` }">
    <div class="fish">
      <div class="fishingrod">
        <img style="z-index: 998" :src="imgs.fishingrod" alt="" />
        <img style="z-index: -1" class="line" :src="imgs.line" alt="" />
      </div>
      <div class="dot"></div>
    </div>
    <van-dialog v-model:show="showSend" :showConfirmButton="false">
      <div class="SendModel sendModel-y">
        <div class="sendOff" @click="close()">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">Get bottle</div>
        <div class="sendBtn" @click="Go()">Go</div>
      </div>
    </van-dialog>
    <van-dialog style="background-color: rgba(0, 0, 0, 0)" v-model:show="showOther" :showConfirmButton="false">
      <div class="modelBox">
        <img class="light" :src="imgs.light" alt="" />
        <img v-show="!boxOpen" class="box" :src="boxInfo?.icon || '/imgs/box/box1.png'" alt="" />
        <img v-show="boxOpen" class="box" :src="boxInfo?.extra?.open_icon || '/imgs/box/box1_open.png'" alt="" />
      </div>
    </van-dialog>
    <van-dialog v-model:show="showGet" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="close()">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :class="hasCoin ? 'coin' : ''" :src="boxInfo?.item?.icon" alt="" />
        <div class="sendTitle" :class="hasCoin ? 'coin-title' : ''">
          X {{ boxInfo?.item?.number }}
        </div>
        <div class="sendContext">
          {{ boxInfo?.describe }}
        </div>
        <div class="sendBtn" @click="close">Confrim</div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="showAnother" :showConfirmButton="false">
      <div class="SendModel sendModel-y">
        <div class="sendOff" @click="close">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="boxIcon || imgs.pearloyster" alt="" />
        <div class="sendTitle">{{ boxInfo?.name }}</div>
        <div class="sendContext">
          {{ boxInfo?.describe }}
        </div>
        <div class="sendBtn" @click="close">Confrim</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { showToast } from 'vant'
import { poolBottleInfo } from '@/config/common/total'
export default {
  components: {},
  data() {
    return {
      boxOpen: false,
      showGet: false,
      showOther: false,
      showAnother: false,
      showSend: false,
      imgs: this.$store.state.sourceMsg,
      initialMouseY: 0,
      initialElementY: 0,
      boxIcon: '',
      boxInfo: {},
    }
  },
  created() {
    this.$store.dispatch('changeDisableScrollEvent', true)
    this.startRotate()
  },
  mounted() {
    document.addEventListener('touchmove', this.preventTouchScroll, {
      passive: false,
    })
  },
  beforeUnmount() {
    document.removeEventListener('touchmove', this.preventTouchScroll)
    this.$store.dispatch('changeDisableScrollEvent', false)
  },
  computed: {
    hasCoin() {
      return this.boxInfo?.item?.icon?.includes('coin')
    },
  },
  methods: {
    preventTouchScroll(event) {
      event.preventDefault()
    },
    // 钓鱼
    fishing() {
      // 请求接口判定 todo
      this.$api
        .poolBottle()
        .then((res) => {
          console.log(res, 'res->>>>')
          if (res.code === 0) {
            this.boxIcon = res?.data?.treasure_box?.icon
            const type = this.transformResponese(res?.data)
            this.getResult(type, res?.data)
            return
          }
          return showToast(res?.message)
        })
        .catch((err) => {
          showToast('Fishing failed')
          this.$router.push('/home')
          console.log(err, 'err')
        })
    },
    startRotate() {
      setTimeout(() => {
        this.fishing()
      }, 2100)
    },
    transformResponese(res) {
      let type = 1
      const dicMap = {
        bottle: 1,
        treasure_box: 2,
        sundries: 3,
      }
      Object.keys(res).forEach((key) => {
        type = dicMap[key]
        poolBottleInfo.value = res[key]
        this.boxInfo = res[key]
      })
      return type
    },
    getResult(type = 1, source) {
      console.log(type, 'type')
      console.log(poolBottleInfo.value, 7777)
      switch (type) {
        // 钓到瓶子
        case 1:
          this.showSend = true
          break
        case 2:
          // 钓到宝箱
          this.boxOpen = false
          this.showOther = true
          let timer1 = setTimeout(() => {
            clearTimeout(timer1)
            this.boxOpen = true
          }, 300)
          let timer2 = setTimeout(() => {
            clearTimeout(timer2)
            this.showOther = false
            this.showGet = true
          }, 1000)
          break
        // 钓到杂物
        case 3:
          this.showAnother = true
          // imgs.pearloyster后续根据返回的icon
          break
      }
    },
    Go() {
      this.$router.push({ path: '/getBottle' })
    },
    // 钓到宝箱后
    close() {
      this.$router.push({ path: '/home' })
    },
  },
}
</script>

<style scoped lang="scss">
.finshiBg {
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 999;
}

.fish {
  width: 10rem;
  position: fixed;
  bottom: 0;

  .fishingrod {
    position: absolute;
    width: 9.65rem;
    z-index: 999;
    animation: moveUp 2s linear forwards;
    bottom: 13.25rem;

    .line {
      width: 0.15rem;
      height: 10rem;
      right: 0.05rem;
      top: 0.1rem;
      position: absolute;
      animation: lineLen 2s linear forwards;
    }
  }

  img {
    width: 100%;
  }
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  bottom: 13rem;
  right: 0;
  display: block;
  position: absolute;
  bottom: 16.3rem;
  right: 0.25rem;
  border-radius: 50%;
  background-color: #a4eafb;
  z-index: 1;
  transform-style: preserve-3d;
  transform: rotateX(65deg);
}

.dot::after {
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #ffffff;
  animation: dot-play 2s linear 400ms infinite;
}

.dot::before {
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #a4eafb;
  animation: dot-play 2s linear 200ms infinite;
  animation-delay: 2s;
  /* 延迟 2s */
}

.SendModel {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;

    img {
      width: 100%;
    }
  }

  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }

  .coin {
    width: 3.55rem;
    height: 3.55rem;
  }

  .coin-title {
    margin-left: 0.5rem;
  }

  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }

  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }

  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}

.sendModel-y {
  flex-direction: column;
}

.modelBox {
  width: 100%;
  height: 8.9rem;
  position: relative;

  // background-color: #fff;
  .light {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 10.4rem;
    height: 10.4rem;
  }

  .box {
    position: absolute;
    top: 2.3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 5.4rem;
    height: 5.4rem;
  }
}

@keyframes dot-play {
  from {
    transform: scale(1);
    opacity: 0.7;
  }

  to {
    transform: scale(12);
    opacity: 0;
  }
}

@keyframes moveUp {
  0% {
    bottom: 13.25rem;
  }

  20% {
    bottom: 14.25rem;
  }

  40% {
    bottom: 13.25rem;
  }

  60% {
    bottom: 14.25rem;
  }

  80% {
    bottom: 13.25rem;
  }

  100% {
    bottom: 18.25rem;
  }
}

@keyframes lineLen {
  0% {
    height: 10rem;
  }

  20% {
    height: 11rem;
  }

  40% {
    height: 10rem;
  }

  60% {
    height: 11rem;
  }

  80% {
    height: 10rem;
  }

  100% {
    height: 15rem;
  }
}
</style>

<template>
  <div class="direction center get_page bgSea">
    <div class="box">
      <div class="top"></div>
      <div class="editBack" :style="{ 'background-image': `url(${senderPaper})` }">
        <div class="avatar"><img :src="senderAvatarUrl" alt="" /></div>
        <div class="userInfo">
          <div class="infoBottom">
            {{ $g.getCountryName(userInfo?.nation) }},&nbsp;{{
              calculateTimeDifference(senderBottleInfo?.created_at)
            }}
          </div>
        </div>
        <div class="content-wrapper">
          <div @click="handlePreview(senderBottleInfo?.config?.url)" v-if="senderBottleInfo?.config?.url"
            class="textImg">
            <img :src="senderBottleInfo?.config?.url" alt="" />
          </div>
          <div class="textContent" v-html="formatText(senderBottleInfo?.content)"></div>
        </div>
      </div>
      <div class="replyTitle">Reply to:</div>
      <div class="editBack" :style="{ 'background-image': `url(${paper})` }">
        <div class="avatar"><img :src="avatarUrl" alt="" /></div>
        <div class="userInfo">
          <div class="infoBottom">
            {{ $g.getCountryName(senderUserinfo?.nation) }},&nbsp;{{
              calculateTimeDifference(senderBottleInfo?.updated_at)
            }}
          </div>
        </div>
        <div class="content-wrapper">
          <div v-if="bottleInfo?.config?.url" @click="handlePreview(bottleInfo?.config?.url)" class="textImg">
            <img :src="bottleInfo?.config?.url" alt="" />
          </div>
          <div class="textContent" v-html="formatText(bottleInfo?.describe)"></div>
        </div>
      </div>
      <div class="bottomText">
        <div class="bottomT" @click="handleReleaseBottle">
          <div class="release">
            <img :src="imgs.send" alt="" />Release bottle
          </div>
        </div>
        <div class="bottomB">
          <div class="copy" @click="Copy" style="margin-right: 0.5rem">
            <img :src="imgs.copy_full" alt="" />Copy
          </div>
          <div class="sink" @click="handleSinkBottle">
            <img :src="imgs.delete_btn" alt="" />Sink bottle
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model:show="showDialog1" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showDialog1 = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.report_full" alt="" />
        <div class="sendTitle">Report user</div>
        <div class="sendContext">
          Does this content seem inappropriate?<br />
          Our monitoring team will then process<br />
          to review the content and take<br />
          appropriate action.
        </div>
        <div class="sendBtn" @click="handleReport">Report</div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="showDialog2" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showDialog2 = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.block_full" alt="" />
        <div class="sendTitle">Block this user</div>
        <div class="sendContext">
          Do you want to block this user? You<br />
          will no longer receive their bottles.
        </div>
        <div class="sendBtn" @click="handelBlock(senderBottleInfo?.tgid)">
          Block
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="showSinkDialog" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showSinkDialog = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.report_full" alt="" />
        <div class="sendTitle">Sink bottle</div>
        <div class="sendContext mb-2">
          This bottle will be permanently deleted
        </div>
        <div class="sinkBottle-btn sink-btn" @click="confrimSinkBottle">
          <!-- 把收到回复的瓶子扔掉 -->
          Sink bottle
        </div>
        <div class="sinkBottle-btn cancel-btn" @click="showSinkDialog = false">
          Cancel
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  formatDate,
  fontFamilyList,
  paperList,
  getAvatar,
  calculateTimeDifferenceFn,
} from '@/config/common/dic'
import { poolBottleInfo, replyListRef } from '@/config/common/total'
import { showToast, showImagePreview } from 'vant'
const base_url = import.meta.env.VITE_BASE_URL
export default {
  components: {},
  data() {
    return {
      showDialog1: false,
      showDialog2: false,
      showMore: false,
      showSinkDialog: false,
      imgs: this.$store.state.sourceMsg,
      url: '',
      fontFamilyList: [],
      paperList: [],
      bottleInfo: {},
      senderBottleInfo: {},
    }
  },
  computed: {
    avatarUrl() {
      return getAvatar(this.bottleInfo?.send_id)
    },
    paper() {
      return (
        this.paperList.find(
          (item) => item.id === this.bottleInfo?.config?.paper
        )?.img || this.$store.state.sourceMsg.paper1
      )
    },
    userInfo() {
      return this.bottleInfo?.user_info
    },
    familyType() {
      return (
        this.fontFamilyList.find(
          (item) => item.id === this.bottleInfo.config?.fontFamily
        )?.fontFamily || this.$store.state.sourceMsg.font1
      )
    },
    senderPaper() {
      return (
        this.paperList.find(
          (item) => item.id === this.senderBottleInfo?.config?.paper
        )?.img || this.$store.state.sourceMsg.paper1
      )
    },
    senderFamilyType() {
      return (
        this.fontFamilyList.find(
          (item) => item.id === this.senderBottleInfo.config?.fontFamily
        )?.fontFamily || this.$store.state.sourceMsg.font1
      )
    },
    senderUserinfo() {
      return this.senderBottleInfo?.user_info
    },
    senderAvatarUrl() {
      return getAvatar(this.senderBottleInfo?.send_id)
    },
  },
  created() {
    this.getBottleInfo()
    if (poolBottleInfo.value?.describe) {
      this.bottleInfo = poolBottleInfo.value
      this.bottleInfo.user_info = this.bottleInfo?.belong_user_info
      if (!this.bottleInfo?.config) {
        this.bottleInfo.config = { paper: 1, fontFamily: 0, url: '' }
      }
      this.senderBottleInfo = this.bottleInfo?.bottle
      this.senderBottleInfo.user_info = this.bottleInfo?.send_user_info
      if (!this.senderBottleInfo?.config) {
        this.senderBottleInfo.config = { paper: 1, fontFamily: 0, url: '' }
      }
    }
    this.fontFamilyList = fontFamilyList()
    this.paperList = paperList()
  },
  mounted() { },
  beforeUnmount() { },
  methods: {
    calculateTimeDifference(time) {
      return calculateTimeDifferenceFn(time)
    },
    handleReport() {
      this.$api.reportBottle(this.bottleInfo.id).then((res) => {
        if (res.code === 0) {
          showToast('Release success')
          this.$router.push('/home')
          return
        }
        showToast(res?.message)
      })
    },
    formatText(text = '') {
      // 使用正则表达式替换文本中的 \n 为 <br> 标签
      return text.replace(/\n/g, '<br>')
    },
    Copy() {
      let textToCopy = this.senderBottleInfo?.content
      // 将文本内容写入剪贴板
      navigator.clipboard.writeText(textToCopy)
      showToast('Copy text to clipboard')
    },
    goReply() {
      this.$router.push('/reply')
    },
    goBack() {
      this.$router.push('/home')
    },
    handleSinkBottle() {
      this.showSinkDialog = true
    },
    confrimSinkBottle() {
      // 删除瓶子
      this.$api.deleteBottle(this.bottleInfo.id).then((res) => {
        if (res.code === 0) {
          replyListRef.value = replyListRef.value.filter(
            (item) => item?.id !== this.bottleInfo.id
          )
          showToast('Sink bottle success')
          this.$router.push('/bottles')
          return
        }
        showToast(res?.message)
      })
    },
    handleReleaseBottle() {
      // 跳转tg，更新好友关系
      this.$api
        .addFriend({
          target_id: this.senderBottleInfo?.owner_id,
          source_bottle_id: this.bottleInfo?.id,
        })
        .then((res) => {
          if (res.code === 0) {
            // 好友todo
            console.log(this.senderBottleInfo.user_info?.username, '->>>>')
            Telegram.WebApp.openTelegramLink(
              `https://t.me/${this.senderBottleInfo.user_info?.username}`
            )
            return
          }
          showToast(res?.message)
        })
    },
    getBottleInfo() {
      // 获取信息todo
      console.log(this.$route?.query, 'query')
    },
    handlePreview(url) {
      console.log(url, 'url->>>')
      url && showImagePreview([url])
    },
    handelBlock(id) {
      this.$api.blockFriend({ target_id: id }).then((res) => {
        if (res.code === 0) {
          showToast('Block success')
          return this.$router.push('/home')
        }
        return showToast(res?.message)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  background: linear-gradient(to bottom, #acecf9, #d6fdfa);
  height: 31vh;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  width: 90%;
  margin: 1rem auto;
  margin-bottom: 0;
  display: flex;
  justify-content: end;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
  }
}

.bottomText {
  height: 7rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    font-size: 0.85rem;
    font-weight: bold;
    color: #fff;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 0.3rem;
    }
  }

  .bottomT {
    width: 80%;

    .release {
      width: 14.05rem;
      height: 2.45rem;
      background: #39cce9;

      img {
        width: 0.65rem;
        height: 0.7rem;
      }
    }
  }

  .bottomB {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;

    .copy {
      border: 1px solid #39cce9;
      width: 5.65rem;
      height: 2.4rem;
      color: #39cce9;
      margin-right: 0.5rem;

      img {
        width: 0.85rem;
      }
    }

    .sink {
      border: 1px solid #39cce9;
      width: 7.7rem;
      height: 2.4rem;
      color: #39cce9;

      img {
        width: 0.85rem;
      }
    }
  }
}

.editBack {
  width: 17rem;
  height: calc(50% - 4rem - 4rem);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: start;
  padding-bottom: 0.5rem;
  position: relative;

  /* overflow: scroll; */
  .content-wrapper {
    display: flex;
    justify-content: space-around;
    overflow: hidden;
  }

  .textImg {
    position: relative;
    width: 50px;
    margin-top: 0.5rem;
    padding-left: 1rem;

    .deleteImg {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 2rem;
      height: 2rem;
    }

    img {
      max-width: 2rem;
      max-height: 6.5rem;
      border-radius: 0.5rem;
      object-fit: contain;
    }
  }

  .avatar {
    position: absolute;
    top: -1.8rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    img {
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 100%;
      background: url('/imgs/bottleType/drift_sea_logo.jpg');
      background-size: contain;
      z-index: 1;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    margin-top: 1.8rem;

    .infoTop {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      word-break: break-word;

      .username {
        font-size: 0.5rem;
        width: 4.65rem;
        height: 1rem;
        line-height: 1rem;
        font-weight: bold;
        background: #ffffff;
        border-radius: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .userage {
        display: flex;
        align-items: center;
        font-size: 0.5rem;

        img {
          width: 0.7rem;
          height: 0.7rem;
          margin: 0 0.3rem;
        }
      }
    }

    .infoBottom {
      font-size: 0.5rem;
      /* margin-bottom: 0.5rem; */
    }
  }

  .textContent {
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
    text-align: start;
    /* height: 65%; */
    word-break: break-word;
    overflow: scroll;
  }
}

.replyTitle {
  width: 4rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  background: #51b8cc;
  font-size: 0.7rem;
  color: #fff;
  line-height: 1.5rem;
  margin-left: 1rem;
}

.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;

    img {
      width: 100%;
    }
  }

  .sendImg {
    width: 4.25rem;
    height: 4.25rem;
  }

  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }

  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }

  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }

  .sinkBottle-btn {
    width: 100%;
    margin: 0.25rem 1rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
    font-weight: 600;
  }

  .sink-btn {
    background: #feebef;
    color: #f03d5a;
  }

  .cancel-btn {
    background: #e7ebf0;
    color: #333;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }
}
</style>

import { TonConnectUI } from "@tonconnect/ui";
import { showLoadingToast, closeToast } from "vant";
import $store from "../../store";
import $api from "@/api/api";
import { beginCell, Cell } from "@ton/ton";
import TonWeb from "tonweb";
let tonweb = new TonWeb();
let $config = import.meta.env;
let payUrl = "";
if ($config.VITE_APP_MODE == "production") {
  payUrl = "https://bbqapp.bbqcoin.ai";
} else {
  payUrl = "https://bgwmoneysocial.buzz";
}
let $pay = {
  manifestUrl: `https://bbqapp.bbqcoin.ai/tonconnect-manifest.json`,
  baseUrl: "https://tonwallet.cash",
  tonConnectUI: "",
};
$pay.tonConnectUI = new TonConnectUI({
  manifestUrl: $pay.manifestUrl,
});
$pay.tonConnectUI.uiOptions = {
  twaReturnUrl: "https://bbqapp.bbqcoin.ai",
};
let requestUrl = $pay.baseUrl;
function getEvent(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", requestUrl + url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (xhr.responseText) {
            return resolve({ data: JSON.parse(xhr.responseText) });
          }
          return reject("Request Error");
        } else {
          return reject("Request Error");
        }
      }
    };
    xhr.send();
  });
}
function postEvent(url, params) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", requestUrl + url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (xhr.responseText) {
            return resolve({ data: JSON.parse(xhr.responseText) });
          }
          return reject("Request Error");
        } else {
          return reject("Request Error");
        }
      }
    };
    let paramsStr = JSON.stringify(params);
    xhr.send(paramsStr);
  });
}
function loadingEvent(text = "loading...") {
  showLoadingToast({
    message: text,
    duration: 100000,
    forbidClick: true,
    loadingType: "circular",
  });
}
function clearLoadingEvent() {
  closeToast();
}
function bindAddress() {}
$pay.checkConnect = () => {
  $pay.tonConnectUI.onStatusChange((walletInfo) => {
    $store.dispatch("changeWalletInfo", $pay.tonConnectUI.wallet || {});
  });
};
$pay.createPayload = (text) => {
  const body = beginCell().storeUint(0, 32).storeStringTail(text).endCell();
  return body.toBoc().toString("base64");
};
$pay.getProofEvent = () => {
  return new Promise(function (resolve, reject) {
    getEvent("/api/ton_proof/generatePayload").then((res) => {
      if (res.data.code == 0) {
        return resolve(res.data.data.payload);
      } else {
        return reject(res.data.msg);
      }
    });
  });
};
$pay.checkProofEvent = (walletInfo) => {
  return new Promise(function (resolve, reject) {
    postEvent("/api/ton_proof/checkProof", {
      proof: walletInfo.proof,
      wallet: walletInfo.account,
    }).then((res) => {
      if (res.data.code == 0) {
        return resolve(res.data.data);
      } else {
        return reject(res.data.msg);
      }
    });
  });
};
$pay.isConnecting = () => {
  if ($pay.tonConnectUI && $pay.tonConnectUI.wallet) {
    return true;
  } else {
    return false;
  }
};
$pay.connect = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if ($pay.isConnecting()) {
        return resolve($pay.tonConnectUI.wallet);
      }
      loadingEvent();
      let payload = await $pay.getProofEvent();
      $pay.tonConnectUI.onStatusChange((walletInfo) => {
        $store.dispatch("changeWalletInfo", $pay.tonConnectUI.wallet || {});
      });
      clearLoadingEvent();
      if ($pay.tonConnectUI) {
        $pay.tonConnectUI.setConnectRequestParameters({
          value: {
            tonProof: payload,
          },
        });
        await $pay.tonConnectUI.connectWallet();
      } else {
        await $pay.init();
        $pay.tonConnectUI.setConnectRequestParameters({
          value: {
            tonProof: payload,
          },
        });
        await $pay.tonConnectUI.connectWallet();
      }
      let walletInfo = $pay.tonConnectUI.wallet;
      let proof =
        walletInfo &&
        (walletInfo.connectItems ? walletInfo.connectItems.tonProof.proof : "");
      if (proof) {
        loadingEvent();
        let token = await $pay.checkProofEvent({
          account: walletInfo.account,
          proof: proof,
        });
        let tokenKey = `${$config.VITE_APP_NAME}_pT`;
        window.localStorage.setItem(tokenKey, token);
        clearLoadingEvent();
      }
      let address = $pay.tonConnectUI.wallet.account.address;
      let userInfo = await $api.connectWallet({
        wallet: $pay.changeAddress(address),
      });
      if (userInfo.data.code === 1) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo.data.data));
        $store.dispatch("changeWalletAdress", userInfo.data.data.wallet);
      }
      return resolve($pay.tonConnectUI.wallet);
    } catch (error) {
      console.log("error=====", error);
      return reject("Connect Error");
    }
  });
};
$pay.pay = (target, num = 1, remark = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      if ($pay.tonConnectUI.wallet) {
        let payRs = await $pay._pay(target, num, remark);
        return resolve(payRs);
      } else {
        await $pay.connect();
        let payRs = await $pay._pay(target, num, remark);
        return resolve(payRs);
      }
    } catch (error) {
      console.log("error1======", error);
      return reject(error.message);
    }
  });
};
$pay._pay = (target, num = 1, remark) => {
  return new Promise(async (resolve, reject) => {
    if (!$pay.tonConnectUI.account) {
      $pay.showModal();
      return reject({ message: "Please select wallet" });
    }
    // if (!num || num <= 0) {
    //     return reject({ message: 'The number must not be less than 0' })
    // }
    if (!target) {
      return reject({ message: "Transfer Failed" });
    }
    let numStr = String(num);

    let uidKey = `${$config.VITE_APP_NAME}_uid`;
    let uid = window.localStorage.getItem(uidKey);
    let payload = "";
    if (uid && !remark) {
      payload = $pay.createPayload(uid);
    }
    if (remark) {
      payload = $pay.createPayload(remark);
    }
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      messages: [
        {
          address: target,
          amount: tonweb.utils.toNano(numStr).toNumber(),
          payload: payload,
        },
      ],
    };
    try {
      const result = await $pay.tonConnectUI.sendTransaction(transaction);
      let pT = window.localStorage.getItem("pT");
      let balance = await tonweb.getBalance($pay.tonConnectUI.account.address);
      if (isNaN(Number(balance))) {
        balance = 0;
      }
      if (balance > 0) {
        balance = tonweb.utils.fromNano(balance);
      }
      let walletInfo = $pay.tonConnectUI.walletInfo;
      walletInfo.account = $pay.tonConnectUI.account;
      walletInfo.balance = balance;
      return resolve({
        boc: result.boc,
        pay_token: pT,
        wallet_info: walletInfo,
      });
    } catch (err) {
      return reject(err.message);
    }
  });
};
$pay.getbalance = async () => {
  if ($pay.tonConnectUI && $pay.tonConnectUI.account) {
    let balance = await tonweb.getBalance($pay.tonConnectUI.account.address);
    if (isNaN(Number(balance))) {
      balance = 0;
    }
    if (balance > 0) {
      balance = tonweb.utils.fromNano(balance);
    }
    return balance;
  }
};
$pay.getWalletInfo = () => {
  return $pay.tonConnectUI.account;
};
$pay.disconnect = () => {
  $pay.tonConnectUI.disconnect();
};
$pay.changeAddress = (address) => {
  if (!address) {
    return address;
  }
  const _address = new tonweb.utils.Address(address);
  const friendly = _address.toString(true, true, false, false);
  return friendly;
};
$pay.decodeBoc = (boc) => {
  return new Promise(async (resolve, reject) => {
    try {
      // boc = "te6cckEBAgEAtgAB4YgAMFUFguXC7W6Ykdw8hzs9uigfnOcT+wA0bjs4YpZ/t9YCnUguIpasL7Fgxegc+tnRqc3xkoXKfajiIO1pdo0kHHgw3xJ72BsL1pSnG4H1gWXSiphXDJWArWMcqEUPfmLgWU1NGLs0M0DgAAAAwAAcAQCAYgBa2hSCvj9Pt4n+u4kRVj6icwaTHjoKC0+TqqxxBfPfOaAOThwAAAAAAAAAAAAAAAAAAAAAAABBcHBTdGFyc1Kj1WQ="
      const cell = Cell.fromBase64(boc);
      const hash = cell.hash().toString("base64");
      // console.log('hash============',hash)
      // let initData = window.Telegram.WebApp.initData
      // if ($config.MODE == 'development') {
      //     initData = $config.VITE_TG_DATA
      // }
      // let initDataJson = $g.fomartInitDataEvent(initData)
      // let params = {
      //     id: initDataJson.user.id,
      //     boc,
      //     _auth: initData,
      // }
      // let result = await postEvent("/api/v1/wallet/message", params);
      // if (result.data.code == 0) {
      //     let hash = result.data.data.hash;
      //     return resolve(hash)
      // }
      // return reject(result.data.message)
      return resolve(hash);
    } catch (error) {
      return reject(error.message);
    }
  });
};
$pay.checkConnect();
export default {
  connect: $pay.connect,
  pay: $pay.pay,
  getbalance: $pay.getbalance,
  getWalletInfo: $pay.getWalletInfo,
  disconnect: $pay.disconnect,
  isConnecting: $pay.isConnecting,
  decodeBoc: $pay.decodeBoc,
  changeAddress: $pay.changeAddress,
};

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home/home.vue";
import Friend from "../views/friend/friend.vue";
import Bottles from "../views/Bottles/bottles.vue";
import Register from "../views/Register/register.vue";
import SendBottle from "../views/SendBottle/sendBottle.vue";
import Achievement from "../views/Achievement/achievement.vue";
import Profile from "../views/Profile/profile.vue";
import Mission from "../views/Mission/mission.vue";
import SendTo from "../views/sendTo/sendto.vue";
import Shopping from "../views/Shopping/shopping.vue";
import Turntable from "../views/Turntable/turntable.vue";
import GetBottle from "../views/GetBottle/getBottle.vue";
import Reply from "../views/Reply/reply.vue";
import GetReplyBottle from "../views/GetReplyBottle/getReplyBottle.vue";
import Fishing from "../views/Fishing/fishing.vue";
import BottomItem from "../views/BottomItem/bottomItem.vue";
import ReplyItem from "../views/ReplyItem/replyItem.vue";
import Test from "../views/TestVue/index.vue";
const routes = [
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/friend",
    name: "friend",
    component: Friend,
  },
  {
    path: "/bottles",
    name: "bottles",
    component: Bottles,
  },
  {
    path: "/achievement",
    name: "achievement",
    component: Achievement,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/mission",
    name: "mission",
    component: Mission,
  },
  {
    path: "/sendBottle",
    name: "sendBottle",
    component: SendBottle,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/sendto",
    name: "sendto",
    component: SendTo,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/shopping",
    name: "shopping",
    component: Shopping,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/turntable",
    name: "turntable",
    component: Turntable,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/getBottle",
    name: "getBottle",
    component: GetBottle,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/reply",
    name: "reply",
    component: Reply,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/getReplyBottle",
    name: "getReplyBottle",
    component: GetReplyBottle,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/fishing",
    name: "fishing",
    component: Fishing,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/bottomItem",
    name: "bottomItem",
    component: BottomItem,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/replyItem",
    name: "replyItem",
    component: ReplyItem,
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: Test,
    meta: {
      hideTabBar: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;


<template>
    <div class="flex center update_content">
        This is update Page
    </div>
 </template>
 <script>
 export default {
   components: {},
   props:{

   },
   data() {
     return {
        
     }
   },
   created(){
    
     
   },
   mounted(){
 
 
   }
 }
 </script>
 <style scoped lang="scss">
 .update_content{
     position: fixed;
     top: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     z-index: 3000;
     background-color: #fff;
 }
 
 </style>
 